import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@hum/icm-app/src/state';
import {
  creditBoxReportsHookUsed,
  creditBoxPullAllReportsButtonClicked,
} from './ducks/actions';
import { CreditBoxSummary } from '@hum/icm-app/src/components/CreditBoxSummary';
import { LineProgressBar } from '@hum/icm-app/src/components/LineProgressBar/index.pc';
import { formatDate } from '@hum/icm-app/src/utils/format';
import { InfoSection, Text, TextType } from '@hum/ui-library';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import {
  useApiMutation,
  createCreditBoxReports,
  useApiQuery,
  getCreditBoxReports,
} from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export const useCreditBoxReport = () => {
  const flags = useFlags();
  const company = useCurrentCompany();
  const companyId = company.data!.id;
  const dispatch = useDispatch();

  const creditBoxReportFromRedux = useSelector(
    (state: AppState) => state.creditBoxReport
  );
  const isDownloadingCreditBoxReport = useSelector(
    (state: AppState) => state.isDownloadingCreditBoxReport
  );

  const {
    data: creditBoxReports,
    isLoading: isLoadingCreditBoxReports,
  } = useApiQuery(getCreditBoxReports, {
    params: { companyId },
    enabled: flags.enabled('use-react-query'),
  });

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(creditBoxReportsHookUsed({ companyId }));
    }
  }, [companyId]);

  const creditBoxReport = flags.enabled('use-react-query')
    ? { loaded: !isLoadingCreditBoxReports, data: creditBoxReports }
    : creditBoxReportFromRedux;

  return {
    creditBoxReport,
    isDownloadingCreditBoxReport,
    companyId,
  };
};

export const CreditBoxInvestors = ({ companyId }: { companyId: number }) => {
  const flags = useFlags();
  const dispatch = useDispatch();

  const { mutate: pullCreditBoxReports } = useApiMutation(
    createCreditBoxReports
  );

  const {
    data: creditBoxReports,
    isLoading: isLoadingCreditBoxReports,
  } = useApiQuery(getCreditBoxReports, {
    params: { companyId },
    enabled: flags.enabled('use-react-query'),
  });

  const creditBoxReport = flags.enabled('use-react-query')
    ? { loaded: !isLoadingCreditBoxReports, data: creditBoxReports }
    : useSelector((state: AppState) => ({
        loaded: state.creditBoxReport.loaded,
        data: state.creditBoxReport.data || [],
      }));

  const isDownloadingCreditBoxReport = useSelector(
    (state: AppState) => state.isDownloadingCreditBoxReport
  );

  const handleClick = useCallback(() => {
    if (flags.enabled('use-react-query')) {
      pullCreditBoxReports({ companyId });
    } else {
      dispatch(creditBoxPullAllReportsButtonClicked({ companyId }));
    }
  }, [flags, companyId, dispatch]);

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(creditBoxReportsHookUsed({ companyId }));
    }
  }, [companyId]);

  return (
    <InfoSection
      testId="admin::credit-box-investors"
      title="SmartRaise credit boxes"
      primaryAction={{
        text: 'Recalculate all',
        onClick: handleClick,
      }}
    >
      {creditBoxReport.loaded && creditBoxReport.data ? (
        creditBoxReport.data.map((report) => (
          <div
            className="p-5 mt-5 text-center bg-surface-subdued rounded-default"
            key={report.id}
          >
            <CreditBoxSummary
              reportId={report.id}
              reportType={report.type}
              companyId={companyId}
              reportDate={formatDate(report.createdAt)}
              reportStatus={report.status}
              reportRating={report.rating}
            />
            {isDownloadingCreditBoxReport &&
              !flags.enabled('use-react-query') && <LineProgressBar />}
          </div>
        ))
      ) : (
        <div className="p-5 mt-5 text-center bg-surface-subdued rounded-default">
          <Text type={TextType.BODY_M}>No credit box matches, yet.</Text>
        </div>
      )}
    </InfoSection>
  );
};
