import { useEffect } from 'react';
import * as Yup from 'yup';
import { pickBy } from 'lodash';
import { useDispatch } from 'react-redux';

import { useField, useForm } from '@hum/common/src/modules/form';
import { aboutYouFormSubmitted } from '@hum/icm-app/src/actions';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import {
  updateSignUpCompany,
  useAnalyticsEvent,
  useApiMutation,
} from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useHistory } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';

export const INVESTMENT_AMOUNT = 'investmentAmount';
export const FUNDRAISING_TIME_FRAME = 'fundraisingTimeFrame';
export const FUNDING_TYPE = 'fundingType';
export const USE_OF_FUNDS = 'useOfFunds';
export const CAPITAL_DEPLOYMENT = 'capitalDeployment';

export type FormValues = {
  [INVESTMENT_AMOUNT]?: string;
  [FUNDRAISING_TIME_FRAME]: string;
  [FUNDING_TYPE]: string;
  [USE_OF_FUNDS]: string;
  [CAPITAL_DEPLOYMENT]: string;
};

export const validationSchema = Yup.object().shape({
  [INVESTMENT_AMOUNT]: Yup.number()
    .required('Required')
    .typeError('Required')
    .integer('Use whole numbers without notations like 1K, 1M, 1B')
    .min(1, 'Please enter an amount greater than $0')
    .max(
      999999999,
      'Please enter an amount less than or equal to $999,999,999'
    ),
  [FUNDRAISING_TIME_FRAME]: Yup.string().required('Required'),
  [USE_OF_FUNDS]: Yup.string().required('Required'),
  [CAPITAL_DEPLOYMENT]: Yup.string(),
  [FUNDING_TYPE]: Yup.string().required('Required'),
});

const INITIAL_VALUES = {
  [FUNDRAISING_TIME_FRAME]: '',
  [INVESTMENT_AMOUNT]: '',
  [USE_OF_FUNDS]: '',
  [FUNDING_TYPE]: '',
};

export function useShareYourGoals() {
  const flags = useFlags();
  const history = useHistory();
  const { mutate: saveSignUpData } = useApiMutation(updateSignUpCompany, {
    onSuccess: () => {
      history.push(Routes.FUNDRAISING_HISTORY);
    },
  });
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const trackEvent = useAnalyticsEvent();
  const form = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (payload) => {
      flags.enabled('use-react-query')
        ? saveSignUpData({
            companyId: company.data!.id,
            application: pickBy({
              investmentAmount: payload[INVESTMENT_AMOUNT],
              fundraisingTimeFrame: payload[FUNDRAISING_TIME_FRAME],
              useOfFunds: payload[USE_OF_FUNDS],
              fundingType: payload[FUNDING_TYPE],
              capitalDeployment: payload[CAPITAL_DEPLOYMENT],
            }),
          })
        : dispatch(
            aboutYouFormSubmitted({
              companyId: company.data!.id,
              payload: {
                application: pickBy({
                  investmentAmount: payload[INVESTMENT_AMOUNT],
                  fundraisingTimeFrame: payload[FUNDRAISING_TIME_FRAME],
                  useOfFunds: payload[USE_OF_FUNDS],
                  fundingType: payload[FUNDING_TYPE],
                  capitalDeployment: payload[CAPITAL_DEPLOYMENT],
                }),
              },
            })
          );
    },
  });

  // heap tracking
  useEffect(() => {
    trackEvent('company-visit-share-your-goals');
  }, []);

  //values we may have from the hum capital site
  useEffect(() => {
    if (company.data?.application.investmentAmount) {
      form.setFieldValue(
        INVESTMENT_AMOUNT,
        company.data?.application.investmentAmount
      );
    }
    if (company.data?.application.fundraisingTimeFrame) {
      form.setFieldValue(
        FUNDRAISING_TIME_FRAME,
        company.data?.application.fundraisingTimeFrame
      );
    }
    if (company.data?.application.useOfFunds) {
      form.setFieldValue(USE_OF_FUNDS, company.data?.application.useOfFunds);
    }
    if (company.data?.application.capitalDeployment) {
      form.setFieldValue(
        CAPITAL_DEPLOYMENT,
        company.data?.application.capitalDeployment
      );
    }
    if (company.data?.application.fundingType) {
      form.setFieldValue(FUNDING_TYPE, company.data?.application.fundingType);
    }
  }, [company.data?.application]);

  const investmentAmount = useField(INVESTMENT_AMOUNT, 'text', form).input;
  const fundraisingTimeFrame = useField(FUNDRAISING_TIME_FRAME, 'select', form)
    .input;
  const useOfFunds = useField(USE_OF_FUNDS, 'text', form).input;
  const capitalDeployment = useField(CAPITAL_DEPLOYMENT, 'text', form).input;
  const fundingType = useField(FUNDING_TYPE, 'select', form).input;

  return {
    form,
    fields: {
      investmentAmount,
      fundraisingTimeFrame,
      useOfFunds,
      capitalDeployment,
      fundingType,
    },
  };
}
