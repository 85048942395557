import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useQuery } from '../../../hooks/useQuery';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

import * as Yup from 'yup';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  setPassword,
  setPasswordWithTos,
  useApiMutation,
  useSessionUser,
} from '@hum/api';
import { getAuthRedirectUrl } from '@hum/icm-app/src/sagas/auth';
import { useHistory } from 'react-router';

const initialValues = {
  password: '',
  acceptedTOS: false,
  acceptedCommunications: true,
};
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your new password')
    .min(10, 'Password is too short, at least 10 characters required'),
});

export const useSetPassword: any = () => {
  const flags = useFlags();
  const { state } = useAppStore();
  const api = useAPI();
  const dispatch = useDispatch();
  const session = useSessionUser();
  const history = useHistory();
  const { mutate: setUserPassword } = useApiMutation(setPassword, {
    onSuccess: () => {
      const user = session.data!;
      const redirectUrl = getAuthRedirectUrl(user, window.location.href);
      if (redirectUrl) {
        const url = new URL(redirectUrl);
        if (url.hostname === window.location.hostname) {
          window.location.assign;
        } else {
          history.push(url.pathname + url.search || '');
        }
      }
    },
  });
  const { mutate: setUserPasswordWithTos } = useApiMutation(setPasswordWithTos);
  const { token = '' }: { token?: string } = useQuery();
  const tosIsAccepted =
    !!state.tosByToken.data && state?.tosByToken?.data?.acceptedTos;
  const reviewedCommunicationsOptIn =
    !!state.tosByToken.data &&
    state?.tosByToken?.data?.reviewCompanyCommunications;
  const getTosByToken = async () => {
    return await api.onboarding.getCompanyTOSByToken(dispatch)(token);
  };
  useEffect(() => {
    getTosByToken();
  }, []);

  const form = useForm({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (payload) => {
      if (tosIsAccepted) {
        if (flags.enabled('use-react-query')) {
          setUserPassword({ ...payload, token: token });
        } else {
          await api.auth.setPassword(dispatch)({ ...payload, token: token });
        }
      } else {
        const acceptanceImpliedFromCopy = true;
        const data = {
          ...payload,
          accepted_tos: acceptanceImpliedFromCopy,
          accepted_privacy_policy: acceptanceImpliedFromCopy,
        };
        if (flags.enabled('use-react-query')) {
          setUserPasswordWithTos({
            ...payload,
            acceptedToS: acceptanceImpliedFromCopy,
            acceptedPrivacyPolicy: acceptanceImpliedFromCopy,
            token,
          });
        } else {
          await api.auth.setPasswordWithTos(dispatch)(data, token);
        }
      }
    },
  });

  const loading = form.isSubmitting || form.submitCount > 0;

  const password = useField('password', 'password', form);
  const acceptedTOS = useField('acceptedTOS', 'checkbox', form).input;
  const acceptedCommunications = useField(
    'acceptedCommunications',
    'checkbox',
    form
  ).input;

  return {
    form,
    loading,
    fields: {
      password,
      acceptedTOS,
      acceptedCommunications,
    },
    tosIsAccepted: tosIsAccepted,
    reviewedCommunicationsOptIn: reviewedCommunicationsOptIn,
    tosIsAcceptedLoaded: state.tosByToken.loaded,
  };
};

export const useSetPasswordV4: any = () => {
  const { token = '' }: { token?: string } = useQuery();
  const dispatch = useDispatch();
  const api = useAPI();

  const form = useForm({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (payload) => {
      await api.auth.setPassword(dispatch)({ ...payload, token: token });
    },
  });

  const loading = form.isSubmitting || form.submitCount > 0;

  const password = useField('password', 'password', form);

  return {
    form,
    loading,
    fields: {
      password,
    },
  };
};
