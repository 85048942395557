import { useEffect } from 'react';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Routes } from '@hum/icm-app/src/state/routes';
import { userVerifyEmailVisited } from '@hum/icm-app/src/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useApiMutation, useSessionUser, verifyEmail } from '@hum/api';
import { getAuthRedirectUrl } from '@hum/icm-app/src/sagas/auth';

export const VerifyEmail = () => {
  const flags = useFlags();
  const session = useSessionUser();
  const history = useHistory();
  const { mutate: verifyUserEmail } = useApiMutation(verifyEmail, {
    onSuccess: () => {
      const user = session.data!;
      const redirectUrl = getAuthRedirectUrl(user, window.location.href);
      if (redirectUrl) {
        const url = new URL(redirectUrl);
        if (url.hostname === window.location.hostname) {
          window.location.assign;
        } else {
          history.push(url.pathname + url.search || '');
        }
      }
    },
  });
  const dispatch = useDispatch();
  const { token = '' }: { token?: string } = useQuery();

  useEffect(() => {
    const verifyToken = async () => {
      //if theres no token there's no point of having the user here
      if (token === '') {
        history.push(Routes.ROOT);
      }
      if (token) {
        flags.enabled('use-react-query')
          ? verifyUserEmail({ token })
          : dispatch(userVerifyEmailVisited({ token }));
      }
    };
    verifyToken();
  }, []);

  return null;
};
